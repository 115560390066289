import React from 'react';
import Form from '@nubank/nuds-web/components/Form/Form';

import { isValidCPF } from 'utils/cpf/isValid';
import { isValidCNPJ } from 'utils/cnpj/isValid';
import { isValidPersonName } from 'utils/personName/isValid';
import { isValidPhone } from 'utils/phone/isValid';

export const extraMasks = {
  cpf: value => (
    value.replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  ),
  cnpj: value => (
    value.replace(/\D/g, '')
      .substring(0, 14)
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/, '$1.$2')
      .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
      .replace(/\.(\d{3})(\d)/, '.$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
  ),
};

const NuDSFormBR = ({ extraValidations, ...props }) => {
  const extraValidationsCombined = {
    ...extraValidations,
    cpf: isValidCPF,
    cnpj: isValidCNPJ,
    personName: isValidPersonName,
    phone: isValidPhone,
    emailCollision: (value = '', _, formValues = {}) => value !== formValues.email,
  };

  return (
    <Form extraMasks={extraMasks} extraValidations={extraValidationsCombined} {...props} />
  );
};

NuDSFormBR.propTypes = Form.propTypes;

NuDSFormBR.Step = Form.Step;

export default NuDSFormBR;
