export function isValidCPF(value) {
  const cpf = value.replace(/\D/g, '');

  const hasEnoughDigits = /\d{3}\d{3}\d{3}(\d{2})$/.test(cpf);
  const isSameDigit = /([0-9])\1{10}/.test(cpf);
  if (isSameDigit || !hasEnoughDigits) {
    return false;
  }

  let sum = 0;
  let rem;

  for (let i = 1; i <= 9; i += 1) sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
  rem = (sum * 10) % 11;

  if (rem === 10 || rem === 11) rem = 0;
  if (rem !== parseInt(cpf.substring(9, 10), 10)) return false;

  sum = 0;
  for (let i = 1; i <= 10; i += 1) sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
  rem = (sum * 10) % 11;

  if (rem === 10 || rem === 11) rem = 0;
  if (rem !== parseInt(cpf.substring(10, 11), 10)) return false;
  return true;
}
