import { sendEvent } from '@nubank/www-latam-commons/services/analytics';

export function isValidPhone(valueDirty) {
  const phoneNumber = valueDirty.replace(/\D/g, '');

  const hasEnoughDigits = /\d{2}\d{4}\d{4}(\d{1})$/.test(phoneNumber);
  if (!hasEnoughDigits) {
    return false;
  }

  const isSameDigit = /([0-9])\1{7}/.test(phoneNumber);
  if (isSameDigit) {
    sendEvent('PHONE_SAME_DIGIT_VALIDATION_FAIL');
    return false;
  }
  return true;
}
